<template>
  <div class="addQuestions">
    <VideoTitle :title="kId ? '修改提问' : '添加提问'"></VideoTitle>
    <div class="addQuestions_conter">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
        <el-form-item label="视频标题" prop="answer">
          <el-input v-model="ruleForm.answer" placeholder="填写视频标题" autocomplete="off" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="视频简介" prop="issue">
          <el-input type="textarea" :rows="6" v-model="ruleForm.issue" placeholder="填写你的视频简介" autocomplete="off" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="上传图片">
          <div class="div">
            <UploadImg :multiple="true" :limit="9" @dialogImage="dialogImage" :filesList="ruleForm.files"></UploadImg>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="submitForm" class="submitForm">确认</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import VideoTitle from '@/components/public/VideoTitle.vue';
import UploadImg from '@/components/public/UploadImg.vue';
import { getOneById, knowledgeBaseInsert, knowledgeBaseUpdate } from '@/utils/serveApi';
export default {
  name: 'addQuestions',
  data() {
    return {
      ruleForm: {
        answer: '',
        issue: '',
        files: []
      },
      rules: {
        answer: [{ required: true, message: '请填写视频标题', trigger: 'blur' }],
        issue: [{ required: true, message: '请填写视频简介', trigger: 'blur' }]
      },
      videoId: '',
      kId: '', //修改提问的id,,
      imgsParse: [],
      subscript: []
    };
  },
  created() {
    const { videoId, id } = this.$route.query;
    this.videoId = videoId;
    this.kId = id;
    if (id) {
      const { answer, issue, imgs } = this.$route.query;
      //编辑
      this.ruleForm.answer = answer;
      this.ruleForm.issue = issue;
      if (imgs.length) {
        const imgsParse = JSON.parse(imgs);
        const files = imgsParse.map(item => {
          return { url: item };
        });
        this.ruleForm.files = files;
        this.imgsParse = imgsParse;
      }
    }
  },
  methods: {
    dialogImage(url, subscript) {
      this.ruleForm.files = url;
      if (subscript) {
        const imgsParse = this.imgsParse;
        const index = imgsParse.indexOf(subscript);
        if (index != -1) this.subscript.push(index + 1);
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.postData();
        } else {
          return false;
        }
      });
    },
    postData() {
      const { answer, issue, files } = this.ruleForm;
      let formData = new FormData();
      files.forEach(item => {
        if (item.name) {
          const files = item.raw;
          formData.append('files', files);
        }
      });
      formData.append('answer', answer);
      formData.append('issue', issue);
      const videoId = this.videoId;
      const kId = this.kId;
      let apiInsertAndUpdate = knowledgeBaseInsert;
      if (kId) {
        formData.append('kId', kId);
        formData.append('subscript', this.subscript.toString());
        apiInsertAndUpdate = knowledgeBaseUpdate;
      } else {
        formData.append('videoId', videoId);
      }
      apiInsertAndUpdate(formData).then(res => {
        if (res.success) {
          this.$message(kId ? '修改成功' : '添加成功');
          this.$router.push({ name: 'managementQuestions', query: { videoId: videoId } });
        }
      });
    }
  },
  components: { VideoTitle, UploadImg }
};
</script>
<style lang="scss">
.addQuestions {
  .el-form-item__label {
    font-size: 20px;
    color: #000000;
  }
  .el-upload--picture-card,
  .el-upload-list__item {
    width: 180px;
    height: 180px;
    border-radius: 10px;
    border: 2px dashed #c1c1c1;
  }
  .upload2 {
    .uploadImg {
      width: 180px;
      height: 180px;
    }
  }
  .videoFiles {
    .el-input {
      display: none;
    }
    .el-form-item__error {
      margin-top: -20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.addQuestions {
  &_conter {
    padding: 30px 40px;
    .submitForm {
      width: 400px;
      height: 58px;
      background: #ffb600;
      border-radius: 8px;
      font-size: 22px;
      color: #ffffff;
      margin: auto;
      display: block;
    }
  }
}
</style>
